import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { ZoomIn } from '@mui/icons-material'
import { capitalize } from '@trinity/utils'
import { ImageNotFound } from '../../assets'
import { Dialog, ImageStepper } from '../../components'
import { useGlobalState, useRemoveMaxWidth, useWindowSize } from '../../hooks'

interface StyleflowVisualizeProps {
  visualizerFabric?: TrinityAPI.VisualizerFabricType
}

export function StyleflowVisualize({ visualizerFabric = undefined }: StyleflowVisualizeProps) {
  useRemoveMaxWidth()
  const { onMobile, onTablet, onLaptop } = useGlobalState()
  const { height } = useWindowSize()
  const [isZoomed, setIsZoomed] = useState(false)
  const isMobile = onMobile || onTablet
  const windowSize = height - 345

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 8, laptop: 0 }} justifyContent='flex-end'>
      <Stack
        bgcolor='neutral.extraLight'
        position={{ mobile: 'absolute' }}
        left={0}
        bottom={{ mobile: 'auto', laptop: 0 }}
        top={{ mobile: 80, laptop: 90 }}
        right={{ mobile: 0, laptop: '50%' }}
        pt={{ mobile: 8, laptop: 0 }}
      >
        <IconButton disableRipple onClick={() => setIsZoomed(true)} sx={{ alignSelf: 'flex-start' }}>
          <ZoomIn color='primary' />
        </IconButton>
        <ImageDialog open={isZoomed} close={() => setIsZoomed(false)} visualizerFabric={visualizerFabric} />
        <ImageStepper alignItems='center' justifyContent='center' height={1}>
          <img
            src={visualizerFabric?.renderedImage ?? ImageNotFound}
            alt={`${visualizerFabric?.fabric?.description} ${visualizerFabric?.garmentType.formalName}`}
            width={isMobile ? 300 : onLaptop ? 500 : 600}
          />
          <img
            src={visualizerFabric?.fabric?.swatch ?? ImageNotFound}
            alt={`${visualizerFabric?.fabric?.description} Swatch`}
            width={isMobile ? 300 : onLaptop ? 500 : 600}
          />
        </ImageStepper>
      </Stack>
      <Stack spacing={4} px={{ laptop: 4 }} pt={{ mobile: 55, laptop: 0 }} width={{ laptop: 1 / 2 }}>
        <Typography variant='h3' sx={{ pl: { mobile: 4, laptop: 0 } }}>
          {visualizerFabric?.fabric
            ? `${visualizerFabric.fabric?.trinityNumber} (${visualizerFabric?.fabric?.position})`
            : `Please Select A ${visualizerFabric ? 'Fabric' : 'Garment'}`}
        </Typography>
        <Typography sx={{ pl: { mobile: 4, laptop: 0 } }}>{visualizerFabric?.fabric?.description}</Typography>
        <Divider sx={{ width: 1 }} />
        <Stack
          height={isMobile ? 'auto' : windowSize}
          sx={isMobile ? {} : { scrollbarWidth: 'thin', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  )
}

interface ImageDialogProps {
  open: boolean
  close: () => void
  visualizerFabric?: TrinityAPI.VisualizerFabricType
}

function ImageDialog({ open, close, visualizerFabric = undefined }: ImageDialogProps) {
  const { onMobile, onTablet } = useGlobalState()

  return (
    <Dialog fullWidth open={open} onClose={close} PaperProps={{ sx: { maxWidth: 1050 } }}>
      <Stack spacing={4} alignItems={{ mobile: 'center', laptop: 'flex-start' }} justifyContent='center'>
        <img
          src={visualizerFabric?.renderedImage ?? ImageNotFound}
          alt={`${visualizerFabric?.fabric?.description} ${visualizerFabric?.garmentType.formalName}`}
          height={onTablet ? 750 : onMobile ? 300 : 'unset'}
        />
        <Stack spacing={2} px={4}>
          <Typography variant='h6' align='center'>
            {visualizerFabric?.description}
          </Typography>
          {visualizerFabric?.relevantMaterials?.map(material => (
            <MaterialDescription key={material} type={material} material={visualizerFabric[material]} />
          ))}
        </Stack>
      </Stack>
    </Dialog>
  )
}

function MaterialDescription({ type, material }: { type: string; material: MaterialType }) {
  const title = capitalize(type)
  const fabricDescription = isFabricMaterial(material) ? `${material?.trinityNumber} - ` : ''

  return (
    <Typography>
      <strong>{title}:</strong> {`${fabricDescription}${material?.description}`}
    </Typography>
  )
}

type MaterialType = TrinityAPI.VisualizerFabricType['fabric'] | TrinityAPI.VisualizerFabricType['button']

const isFabricMaterial = (material: MaterialType): material is TrinityAPI.VisualizerFabricType['fabric'] =>
  'trinityNumber' in (material ?? {})
