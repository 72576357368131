import { useEffect, useState } from 'react'
import { Form, useFetcher, useFetchers, useNavigate } from 'react-router-dom'
import { Button, Dialog, Divider, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { Check, Delete, Edit } from '@mui/icons-material'
import { formatDate } from '@trinity/utils'
import { BackButton, ImageCardList } from '../../components'
import { useGlobalState } from '../../hooks'

interface StyleflowStylepageProps {
  stylepage: TrinityAPI.VisualizerStylepageType
  deleteConfirmation?: string
}

export function StyleflowStylepage({ stylepage, deleteConfirmation = undefined }: StyleflowStylepageProps) {
  const { setToastInfo } = useGlobalState()
  const navigate = useNavigate()
  const fetchers = useFetchers()
  const fetcher = fetchers.find(fetcher => fetcher.key === ImageCardList.FETCHER_KEY)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const handleClose = () => setShowConfirmation(false)

  useEffect(() => {
    if (fetcher?.data?.message) {
      setToastInfo({ show: true, severity: 'success', message: fetcher?.data?.message })
    }
  }, [fetcher?.data, setToastInfo])

  useEffect(() => {
    if (deleteConfirmation) {
      setToastInfo({ show: true, severity: 'success', message: deleteConfirmation })
      navigate('/styleflow/stylepages')
    }
  }, [deleteConfirmation, navigate, setToastInfo])

  if (!stylepage) return null

  const items = stylepage.items.map(item => ({
    key: item.id,
    image: item.image,
    dividerText: item.baseModel.description,
    primaryText: item.fabric.description,
    secondaryText: formatDate(item.updatedAt),
    hoverItems: [
      item.lining ? ['Lining', item.lining.trinityNumber] : [],
      item.button ? ['Button', item.button.description] : [],
      item.thread ? ['Thread', item.thread.description] : [],
      item.ribknit ? ['Ribknit', item.ribknit.description] : [],
      item.zipper ? ['Zipper', item.zipper.description] : [],
    ],
    item,
  }))

  return (
    <Stack spacing={4}>
      <BackButton to='/styleflow/stylepages' />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <TitleBar stylepage={stylepage} />
        <Button
          size='small'
          color='error'
          startIcon={<Delete />}
          onClick={() => setShowConfirmation(true)}
          sx={{ borderRadius: 5 }}
        >
          Delete Stylepage
        </Button>
      </Stack>
      <Typography variant='body3' sx={{ fontStyle: 'italic' }}>
        Last Edited: {formatDate(stylepage.updatedAt)}
      </Typography>
      <Divider />
      <Typography variant='h3'>Items</Typography>
      <ImageCardList items={items} actions={['add', 'delete', 'edit']}>
        {item => (
          <>
            <input type='hidden' name='id' value={item.id} />
            <input type='hidden' name='baseModelId' value={item.baseModel.id} />
            <input type='hidden' name='fabricId' value={item.fabric?.id} />
            <input type='hidden' name='garmentType' value={item.garmentType} />
            <input type='hidden' name='imageUri' value={item.image} />
            <input type='hidden' name='liningId' value={item.lining?.id} />
            <input type='hidden' name='buttonId' value={item.button?.id} />
            <input type='hidden' name='threadId' value={item.thread?.id} />
            <input type='hidden' name='ribknitId' value={item.ribknit?.id} />
            <input type='hidden' name='zipperId' value={item.zipper?.id} />
          </>
        )}
      </ImageCardList>
      <Dialog open={showConfirmation} onClose={handleClose}>
        <Form method='POST'>
          <Stack spacing={4} p={4}>
            <Typography variant='h3' align='center'>
              Please Confirm
            </Typography>
            <Typography>This stylepage and all related items will be permanantly deleted.</Typography>
            <input type='hidden' name='id' value={stylepage.id} />
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <Button size='small' variant='outlined' onClick={handleClose}>
                Cancel
              </Button>
              <Button
                size='small'
                color='error'
                type='submit'
                name='intent'
                value='delete-stylepage'
                onClick={handleClose}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Dialog>
    </Stack>
  )
}

function TitleBar({ stylepage }: { stylepage: TrinityAPI.VisualizerStylepageType }) {
  const { setToastInfo } = useGlobalState()
  const fetcher = useFetcher()
  const [mode, setMode] = useState<'view' | 'edit'>('view')

  useEffect(() => {
    if (fetcher.data?.message) {
      setMode('view')
      setToastInfo({ show: true, severity: 'success', message: fetcher.data.message })
    }
  }, [fetcher.data, setToastInfo])

  if (!stylepage) return null

  if (mode === 'view') {
    return (
      <Stack direction='row' spacing={4}>
        <Typography variant='h2'>{stylepage.name}</Typography>
        <Tooltip placement='top' title='Edit Stylepage'>
          <IconButton onClick={() => setMode('edit')}>
            <Edit sx={{ bgcolor: 'neutral.extraLight' }} />
          </IconButton>
        </Tooltip>
      </Stack>
    )
  }

  if (mode === 'edit') {
    return (
      <fetcher.Form method='POST'>
        <Stack direction='row' spacing={4}>
          <TextField label='Stylepage Name' name='name' defaultValue={stylepage.name} />
          <input type='hidden' name='id' value={stylepage.id} />
          <Tooltip placement='top' title='Save Stylepage'>
            <IconButton type='submit' name='intent' value='edit-stylepage'>
              <Check sx={{ bgcolor: 'neutral.extraLight' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </fetcher.Form>
    )
  }

  return null
}
