import { useEffect } from 'react'
import { useLocation, useNavigate, Link, useFetcher, useSearchParams } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid, Button } from '@mui/material'
import { capitalCase } from '@trinity/utils'
import { ImageNotFound } from '../../assets'
import { Expand, MaterialCard } from '../../components'
import { useGlobalState } from '../../hooks'

interface StyleflowVisualizeMaterialsProps {
  visualizerFabric?: TrinityAPI.VisualizerFabricType
}

export function StyleflowVisualizeMaterials({ visualizerFabric = undefined }: StyleflowVisualizeMaterialsProps) {
  const { setToastInfo } = useGlobalState()
  const fetcher = useFetcher<{ error: boolean; message: string }>()
  const [params] = useSearchParams()
  const rackId = params.get('rackId')
  const isEditMode = Boolean(rackId)
  const referrer = params.get('referrer') || 'collections'

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      setToastInfo({ show: true, message: fetcher.data.message, severity: fetcher.data.error ? 'error' : 'success' })
    }
  }, [fetcher, setToastInfo])

  return (
    <Stack spacing={4} pb={8}>
      <GarmentCard visualizerFabric={visualizerFabric} />
      {visualizerFabric && <MaterialCards visualizerFabric={visualizerFabric} />}
      {visualizerFabric && (
        <Stack
          direction={{ mobile: 'column', desktop: 'row' }}
          alignItems='center'
          justifyContent='center'
          spacing={{ mobile: 2, laptop: 4 }}
        >
          <Button
            onClick={() => fetcher.submit(getMaterials(visualizerFabric, rackId), { method: 'POST' })}
            sx={{ borderRadius: 50 }}
          >
            {isEditMode ? 'Save Item' : '+ On The Rack'}
          </Button>
          <Link to={`/styleflow/${referrer}`}>
            <Button variant='outlined' sx={{ borderRadius: 50 }}>
              Start Over
            </Button>
          </Link>
        </Stack>
      )}
    </Stack>
  )
}

function GarmentCard({ visualizerFabric = undefined }: StyleflowVisualizeMaterialsProps) {
  const location = useLocation()

  return (
    <Expand>
      <Stack
        component={Link}
        to={`garmentType${location.search}`}
        direction='row'
        spacing={4}
        alignItems='center'
        p={2}
        mx={{ laptop: 2 }}
        sx={{ boxShadow: theme => theme.elevation.button, textDecoration: 'none' }}
      >
        <img
          src={visualizerFabric?.garmentType.image ?? ImageNotFound}
          alt={visualizerFabric?.garmentType.formalName}
          height={85}
          width={70}
        />
        <Stack>
          <Typography variant='h6'>Garment Type</Typography>
          <Typography variant='smallBody1'>
            {visualizerFabric
              ? `${visualizerFabric?.garmentType.formalName} | ${visualizerFabric?.description}`
              : 'Please Choose'}
          </Typography>
        </Stack>
      </Stack>
    </Expand>
  )
}

function MaterialCards({ visualizerFabric }: Required<StyleflowVisualizeMaterialsProps>) {
  const navigate = useNavigate()
  const { relevantMaterials } = visualizerFabric

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
      {relevantMaterials.map(material => {
        const materialType = visualizerFabric[material]

        return (
          <Grid key={material}>
            <MaterialCard
              error={!materialType}
              onClick={() => navigate(`${material}${location.search}`)}
              image={materialType?.image}
              headerText={materialType ? capitalCase(material) : `Please Select ${capitalCase(material)}`}
              subText={isFabric(materialType) ? materialType.trinityNumber : materialType?.description}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

//* HELPERS
const isFabric = (material: Record<string, unknown> | null): material is { trinityNumber: string } => {
  if (!material) return false

  return 'trinityNumber' in material
}

const getMaterials = (visualizerFabric: TrinityAPI.VisualizerFabricType, rackId: string | null) => {
  const { baseModel, garmentType, renderedImage, ...materials } = visualizerFabric
  const materialParams: Record<string, string | number> = {
    baseModelId: baseModel,
    garmentType: garmentType.bitmask,
    imageUri: renderedImage,
  }

  if (rackId) materialParams.rackId = rackId

  Object.entries(materials).forEach(([key, value]) => {
    if (value && typeof value === 'object' && 'id' in value) {
      materialParams[`${key}Id`] = value.id
    }
  })

  return materialParams
}
