import { cloneElement, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { AnimatePresence, LayoutGroup, motion } from 'motion/react'
import {
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
  type IconButtonProps,
  Tooltip,
  Box,
  Button,
} from '@mui/material'
import { Add, Delete, Edit, KeyboardReturn, type SvgIconComponent } from '@mui/icons-material'
import { useGlobalState } from '../../hooks'
import { Dialog } from '../Dialog'

export function ImageCardList<ItemType>({ items, actions = [], children = undefined }: ImageCardListProps<ItemType>) {
  if (items.length < 1)
    return (
      <Typography variant='h6' align='center'>
        There are no items to display.
      </Typography>
    )

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      <LayoutGroup>
        <AnimatePresence mode='popLayout'>
          {items.map(item => (
            <Grid key={item.key}>
              <motion.div
                layout
                initial={{ scale: 0.5, opacity: 0.1 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.25, opacity: 0 }}
                transition={{ type: 'tween' }}
                key={item.key}
              >
                <ImageCard item={item} actions={actions}>
                  {children ? cloneElement(children(item.item)) : null}
                </ImageCard>
              </motion.div>
            </Grid>
          ))}
        </AnimatePresence>
      </LayoutGroup>
    </Grid>
  )
}

export function ImageCard<ItemType>({ item, actions, children = null }: ImageCardProps<ItemType>) {
  const { onMobile } = useGlobalState()
  const fetcher = useFetcher({ key: IMAGE_CARD_FETCHER_KEY })
  const [isHovered, setIsHovered] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const handleClose = () => setShowConfirmation(false)
  const handleDelete = () => {
    setIsHovered(false)
    setShowConfirmation(true)
  }

  return (
    <fetcher.Form method='POST'>
      <motion.div layout transition={{ duration: 0.25 }}>
        <Stack
          spacing={2}
          boxShadow={theme => theme.elevation.card}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Stack direction='row' pt={2} justifyContent='space-evenly'>
            <ActionButton actions={actions} action='edit' />
            <img src={item.image} alt='Garment' height={onMobile ? 230 : 300} />
            <ActionButton actions={actions} action='add' />
          </Stack>
          <Divider>{item.dividerText && <Typography variant='body2'>{item.dividerText}</Typography>}</Divider>
          <Stack spacing={1} px={2} pb={2}>
            {children}
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography noWrap variant='h5' sx={{ maxWidth: 220 }}>
                {item.primaryText}
              </Typography>
              <ActionButton actions={actions} action='delete' onClick={handleDelete} />
              <ActionButton actions={actions} action='restore' />
            </Stack>
            <Typography noWrap variant='smallBody2' sx={{ maxWidth: 220 }}>
              {item.secondaryText}
            </Typography>
            {isHovered &&
              item.hoverItems
                ?.filter(i => i.length)
                ?.map(([title, description]) => (
                  <Stack key={title} direction='row' spacing={2}>
                    <Typography noWrap variant='smallBody3' sx={{ fontWeight: 600 }}>
                      {`${title}:`}
                    </Typography>
                    <Typography noWrap variant='smallBody2' sx={{ maxWidth: 190 }}>
                      {description}
                    </Typography>
                  </Stack>
                ))}
          </Stack>
        </Stack>
      </motion.div>
      <Dialog open={showConfirmation} onClose={handleClose}>
        <fetcher.Form method='POST'>
          <Stack spacing={4} p={4}>
            <Typography variant='h5'>Are you sure you want to remove this item?</Typography>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <input type='hidden' name='id' value={item.key} />
              <Button size='small' variant='outlined' onClick={handleClose}>
                Cancel
              </Button>
              <Button size='small' color='error' type='submit' name='intent' value='delete' onClick={handleClose}>
                Delete
              </Button>
            </Stack>
          </Stack>
        </fetcher.Form>
      </Dialog>
    </fetcher.Form>
  )
}

//* -------------------- HELPERS -------------------- *//
const IMAGE_CARD_FETCHER_KEY = 'imageCard'
ImageCardList.FETCHER_KEY = IMAGE_CARD_FETCHER_KEY
ImageCard.FETCHER_KEY = IMAGE_CARD_FETCHER_KEY
const StyledIcon = ({ sx, ...props }: IconButtonProps) => (
  <IconButton disableRipple size='small' edge='end' sx={{ alignItems: 'flex-start', ...sx }} {...props} />
)

const BlankSpace = () => <Box px={2.84} />

function ActionButton({ actions, action, ...props }: ActionButtonProps) {
  const canDelete = actions.includes('delete')
  const canRestore = actions.includes('restore')
  const canEdit = actions.includes('edit')
  const canAdd = actions.includes('add')

  switch (action) {
    case 'delete':
      if (canDelete) return <StyledIconButton Icon={Delete} tooltip='Remove' type='button' {...props} />
      return null
    case 'restore':
      if (canRestore) return <StyledIconButton Icon={KeyboardReturn} tooltip='Restore' value='restore' {...props} />
      return null
    case 'edit':
      if (canEdit) return <StyledIconButton Icon={Edit} tooltip='Edit' value='edit' sx={{ pl: 2 }} {...props} />
      return <BlankSpace />
    case 'add':
      if (canAdd) return <StyledIconButton Icon={Add} tooltip='Add to Rack' value='add' sx={{ pr: 2 }} {...props} />
      return <BlankSpace />
  }
}

function StyledIconButton({ Icon, tooltip, ...props }: StyledIconButtonProps) {
  return (
    <StyledIcon type='submit' name='intent' {...props}>
      <Tooltip placement='top' title={tooltip}>
        <Icon sx={{ bgcolor: 'neutral.extraLight', p: 0.5 }} />
      </Tooltip>
    </StyledIcon>
  )
}

//* -------------------- TYPES -------------------- *//
type ActionsType = 'edit' | 'add' | 'delete' | 'restore'

interface ItemsType<ItemType> {
  key: string | number
  image: string
  dividerText?: string
  primaryText: string
  secondaryText?: string
  hoverItems?: string[][]
  item: ItemType
}

interface ImageCardListProps<ItemType> {
  items: ItemsType<ItemType>[]
  actions?: Array<ActionsType>
  children?: (item: ItemType) => JSX.Element
}

interface ImageCardProps<ItemType> {
  item: ItemsType<ItemType>
  actions: Array<ActionsType>
  children?: React.ReactNode
}

interface ActionButtonProps extends Omit<IconButtonProps, 'action'> {
  actions: Array<ActionsType>
  action: ActionsType
}

interface StyledIconButtonProps extends IconButtonProps {
  Icon: SvgIconComponent
  tooltip: string
}
