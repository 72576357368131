import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { AxiosError } from 'axios'
import { API, handleError } from './api'
import { fabricImageSchema, visualizerFabricSchema, visualizerRackSchema, visualizerStylepageSchema } from './schemas'
import { cleanObject } from '../helpers'

//* ---------------- APIS ---------------- *//
export async function getVisualizerFabrics(rawParams: Record<string, unknown>) {
  try {
    const params = getVisualizerFabricsParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics', { params })
    const fabricImages = z.array(fabricImageSchema).parse(response.data)

    return fabricImages
  } catch (error) {
    return handleError(error, 'Collection Images')
  }
}

export async function getVisualizerFabric(rawParams: Record<string, unknown>) {
  try {
    const params = getVisualizerFabricParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics/fabric', { params })
    const fabric = visualizerFabricSchema.parse(response.data)

    return fabric
  } catch (error) {
    return handleError(error, 'Visualizer Fabrics')
  }
}

export async function getVisualizerRackItems() {
  try {
    const response = await API.get(`visualizer_racks`)
    const rackItems = z.array(visualizerRackSchema).parse(response.data)

    return rackItems
  } catch (error) {
    return handleError(error, 'Rack Items')
  }
}

export async function getVisualizerRackItem(id: string | number) {
  try {
    const response = await API.get(`visualizer_racks/${id}`)
    const rackItem = visualizerRackSchema.parse(response.data)

    return rackItem
  } catch (error) {
    return handleError(error, 'Rack Item')
  }
}

export async function createVisualizerRack(rawParams: Record<string, unknown>) {
  try {
    const params = createVisualizerRackParams.parse(cleanObject(rawParams))

    await API.post('visualizer_racks', params)

    return { error: false, message: 'Garment successfully added to Rack' }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return {
        error: true,
        message:
          'We were unable to add this garment to the rack. Please try again or contact support if you continue to see this error',
      }
    } else {
      return handleError(error, 'Create Rack Item')
    }
  }
}

export async function updateVisualizerRack(rawParams: Record<string, unknown>) {
  try {
    const { id, ...params } = updateVisualizerRackParams.parse(rawParams)

    await API.put(`visualizer_racks/${id}`, params)

    return { error: false, message: 'Item successfully updated' }
  } catch (error) {
    return handleError(error, 'Update Rack Item')
  }
}

export async function deleteVisualizerRack(id: number) {
  try {
    const response = await API.delete(`visualizer_racks/${id}`)

    return response
  } catch (error) {
    return handleError(error, 'Delete Rack Item')
  }
}

export async function getVisualizerStylepages(rawParams?: Record<string, unknown>) {
  try {
    const params = getVisualizerStylepagesParams.parse(rawParams)
    const response = await API.get(`visualizer_stylepages`, { params })
    const stylepages = z.array(visualizerStylepageSchema).parse(response.data)
    const totalStylepages = Number(response.headers.total)

    return { stylepages, totalStylepages }
  } catch (error) {
    return handleError(error, 'Stylepages')
  }
}

export async function getVisualizerStylepage(id: number) {
  try {
    const response = await API.get(`visualizer_stylepages/${id}`)
    const stylepage = visualizerStylepageSchema.parse(response.data)

    return stylepage
  } catch (error) {
    return handleError(error, 'Stylepage')
  }
}

export async function createVisualizerStylepage(rawParams: Record<string, unknown>) {
  try {
    const params = cleanObject(createVisualizerStylepageParams.parse(rawParams))
    const response = await API.post('visualizer_stylepages', params)
    const stylepage = visualizerStylepageSchema.parse(response.data)

    return stylepage
  } catch (error) {
    return handleError(error, 'Create Stylepage')
  }
}

export async function updateVisualizerStylepage(rawParams: Record<string, unknown>) {
  try {
    const { id, ...params } = updateVisualizerStylepageParams.parse(rawParams)
    await API.put(`visualizer_stylepages/${id}`, params)

    return { error: false, message: 'Stylepage updated successfully' }
  } catch (error) {
    return handleError(error, 'Update Stylepage')
  }
}

export async function addVisualizerStylepageItems(id: number) {
  try {
    const response = await API.post(`visualizer_stylepages/${id}/add_items`)

    return response
  } catch (error) {
    return handleError(error, 'Add Stylepage Items')
  }
}

export async function deleteVisualizerStylepage(id: number) {
  try {
    const response = await API.delete(`visualizer_stylepages/${id}`)

    return response
  } catch (error) {
    return handleError(error, 'Delete Stylepage')
  }
}

//* ---------------- PARAMS ---------------- *//
const getVisualizerFabricsParams = z
  .object({
    collectionId: z.coerce.number(),
    garmentType: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const getVisualizerFabricParams = z
  .object({
    baseModelId: z.coerce.number(),
    fabricId: z.coerce.number().optional(),
    liningId: z.coerce.number().optional(),
    buttonId: z.coerce.number().optional(),
    threadId: z.coerce.number().optional(),
    zipperId: z.coerce.number().optional(),
    ribknitId: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const createVisualizerRackParams = z
  .object({
    garmentType: z.coerce.number(),
    baseModelId: z.coerce.number(),
    imageUri: z.string().url(),
    fabricId: z.coerce.number().optional(),
    liningId: z.coerce.number().optional(),
    buttonId: z.coerce.number().optional(),
    threadId: z.coerce.number().optional(),
    ribknitId: z.coerce.number().optional(),
    zipperId: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const updateVisualizerRackParams = z
  .object({
    rackId: z.coerce.number(),
    garmentType: z.coerce.number(),
    baseModelId: z.coerce.number(),
    imageUri: z.string().url(),
    fabricId: z.coerce.number(),
    liningId: z.coerce.number().nullable().default(null),
    buttonId: z.coerce.number().nullable().default(null),
    threadId: z.coerce.number().nullable().default(null),
    ribknitId: z.coerce.number().nullable().default(null),
    zipperId: z.coerce.number().nullable().default(null),
  })
  .transform(({ rackId, ...data }) => ({ id: rackId, ...data }))
  .transform(objectToSnake)

const getVisualizerStylepagesParams = z
  .object({
    q: z.string().optional(),
    page: z.coerce.number().optional(),
    perPage: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const createVisualizerStylepageParams = z
  .object({
    name: z.string(),
    addRackItems: z.coerce.number().default(1),
  })
  .transform(objectToSnake)

const updateVisualizerStylepageParams = z
  .object({
    id: z.coerce.number(),
    name: z.string(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
